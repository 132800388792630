<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Creditop
        </h2>

        <!-- <b-img
          :src="require('@/assets/images/logo/creditop.jpeg')"
          fluid
          alt="Homecenter"
          width="150px"
        /> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

        <h2 class="text-center">Estos son algunos de nuestros aliados</h2>

          <div class="mt-4">

            <b-card
              v-for="allie in allies" :key="allie.allie"
              :img-src="allie.logoImage"
              img-top
              img-alt="card img"
              class="mb-3 hover-card"
              @click="$router.push('aliados/onboarding?allie=' + allie.allie)"
              no-body
            >
              
            </b-card>

              
          </div>

          <b-card-text class="mb-2 mt-4 text-center text-muted">
            Derechos reservados Creditop
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>

  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BImg, BButton, BCardText,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { codeMask } from '@/views/forms/form-element/form-input-mask/code'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    BCardText,
    VuexyLogo,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      clientid: process.env.VUE_APP_CLIENTID,
      flowid: process.env.VUE_APP_FLOWID,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      allieOnboarding: '',
      allies: [
        {
          allie: 'homecenter',
          name: 'Homecenter',
          copy: 'Solicita ya mismo tu crédito y no esperes más para tener lo que tu hogar necesita',
          logoImage: require('@/assets/images/allies/logo-homecenter_1.jpg'),
        },
        {
          allie: 'estetica',
          name: 'Dra Luz Marina - Medicina Estética',
          copy: 'Estar en manos expertas, garantiza resultados visibles, seguros y duraderos, ven y mejora tu apariencia física y tu bienestaro',
          logoImage: require('@/assets/images/allies/logo-homecenter_1.jpg'),
        },
      ],
      codeMask,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // this.allieOnboarding = this.$route.query.allie
    // console.log(process.env.VUE_APP_ENDPOINT)
    // console.log(this.$route.query.allie)
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

metamap-button {
  display: block;
  width: 100%;
}

</style>
